<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("SPOTS.SPOT_MAIN_IMAGE") }}</h3>
    <div class="spot-images">
      <div class="spot-images-main">
        <img v-if="spot.picture" :src="$pictureResize(spot.picture, 300)" class="argon-image" />
        <img
          v-else
          src="/img/kw-default-image.svg"
          class="argon-image default"
        />
      </div>
      <div v-if="spot.gallery.length > 0" class="spot-images-list">
        <h3>{{ $t("SPOTS.SPOT_OTHER_IMAGE") }}</h3>
        <div class="row">
          <div class="image" v-for="(image, key) in spot.gallery.filter(item => !!item)" :key="key">
            <img :src="$pictureResize(image, 300)" class="argon-image" style="width: 100%" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "spot-view-gallery",

  props: ["spot"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    spotUpdated() {
      this.$emit("spotUpdated", true);
    },
  },

  mounted() {},

  watch: {
    spot(spot) {},
  },
};
</script>
