<template>
  <div
    v-if="
      $currentUserCan($permissions.PERM_VIEW_ANY_SPOT_CATEGORIES) && spotType
    "
  >
    <router-link :to="$objectViewRoute(spotType)">
      {{ `${spotType.name}` }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "spot-type",

  components: {},

  mixins: [formMixin],

  props: {
    spotType: {
      type: Object,
      default: () => ({}),
      description: "Spot Type",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    spotType(spotType) {},
  },
};
</script>
