<template>
  <el-select
    v-model="equipmentModel"
    @change="equipmentChanged"
    autocomplete="new-password"
    collapse-tags
    :placeholder="placeholder ?? $t('COMMON.EQUIPMENTS')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getEquipments"
    :loading="loading"
    :allow-create="allowCreate"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="equipment in equipments"
      :key="equipment.id"
      :value="equipment.id"
      :label="equipment.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "equipment-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    allowCreate: {
      type: Boolean,
      default: false,
      description: "Allow tag creation",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    equipment: {
      type: String,
      default: null,
      description: "Equipment id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder",
    },
  },

  data() {
    return {
      equipmentModel: this.equipment,
      equipments: {},
    };
  },

  setup() {},

  created() {
    this.getEquipments(null, this.equipment);
  },

  methods: {
    async getEquipments(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        await this.$store.dispatch("equipments/list", params);
        const equipmentsArr = await this.$store.getters["equipments/list"];
        this.equipments = {};
        equipmentsArr.forEach((equipment) => {
          this.equipments[equipment.id] = equipment;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    equipmentChanged(equipment) {
      this.$emit("equipmentChanged", equipment);
    },
  },

  watch: {
    equipment(equipment) {
      if (equipment) {
        this.equipmentModel = equipment;
        if (equipment !== this.equipmentModel) {
          this.getEquipments(null, equipment);
        }
      } else {
        this.equipmentModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getEquipments();
    },
  },
};
</script>
