<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            type="search"
            class="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <!-- <organization-selector
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            @organizationChanged="
              (organizationId) => (selectedOrganization = organizationId)
            "
          /> -->
          <base-input
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
            :placeholder="`${$t('COMMON.LOCATIONS')}`"
          >
            <locations-selector
              @locationsChanged="(locations) => (selectedLocations = locations)"
            />
          </base-input>
          <spot-category-selector
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SPOT_CATEGORIES)"
            :showAll="false"
            :multiple="true"
            @spotCategoryChanged="
              (spotCategories) => (selectedSpotCategories = spotCategories)
            "
          />
          <spot-type-selector
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SPOT_TYPES)"
            :placeholder="$t(`SPOTS.SPOT_TYPE`)"
            :showAll="false"
            :multiple="true"
            @spotTypeChanged="(spotTypes) => (selectedSpotTypes = spotTypes)"
          />
          <equipment-selector
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_EQUIPMENTS)"
            :placeholder="$t(`SPOTS.SPOT_EQUIPMENTS`)"
            :showAll="false"
            :multiple="true"
            @equipmentChanged="
              (equipments) => (selectedEquipments = equipments)
            "
          />
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="page-wrapper-table-body">
        <loading-panel v-if="loading"></loading-panel>
        <div
          v-bind:style="{ visibility: loading ? 'hidden' : 'visible' }"
          class="page-wrapper-table-body-inner"
        >
          <el-table
            class="align-items-center table-flush clickable"
            header-row-class-name="thead-light"
            :data="spots"
            :empty-text="$t('COMMON.NO_SEARCH_RESULT')"
            @sort-change="sortChange"
            @row-click="selectRow"
          >
            <!-- <el-table-column min-width="90" :label="$t('COMMON.IMAGE')">
              <template v-slot="{ row }">
                <div class="spot-image">
                  <div class="spot-image-wrapper">
                    <img
                      v-if="row.picture"
                      :src="row.picture"
                      class="avatar rounded-circle"
                    />
                  </div>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column
              :label="$t('SPOTS.SPOT_NUMBER')"
              prop="code"
              sortable="custom"
              min-width="170"
            />
            <el-table-column
              :label="$t('SPOTS.SPOT_NAME')"
              prop="name"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.name ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CATEGORY')"
              prop="category.name"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span class="category" v-if="row.category">
                  <img
                    v-if="row.category.icon"
                    :src="row.category.icon"
                    class="avatar"
                  />
                  {{ row.category?.name }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.TYPE')"
              prop="spotType.name"
              min-width="200"
            />
            <el-table-column :label="$t('SPOTS.SPOT_CAPACITY')" min-width="120">
              <template v-slot="{ row }">
                <span class="icon-text">
                  <img src="/img/kw-persons.svg" alt="icon" />
                  {{ row.capacity }} pers
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('SPOTS.SPOT_PET_CAPACITY')"
              min-width="100"
            >
              <template v-slot="{ row }">
                <span class="icon-text">
                  <img src="/img/kw-pets.svg" alt="icon" />
                  {{ row.pets_capacity > 0 ? "Oui" : "Non" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('SPOTS.SPOT_EQUIPMENTS')"
              prop="equipments"
              min-width="300"
            >
              <template v-slot="{ row }">
                {{ row.other_equipments.map((item) => item.name).join(", ") }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('COMMON.STATUS')" min-width="150">
              <template v-slot="{ row }">
                <span
                  :class="row.status === 'AVAILABLE' ? 'active' : 'unactive'"
                >
                  {{ $t(`SPOTS.SPOT_STATUS_${row.status}`) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('SPOTS.CLEAN_CONDITION')"
              min-width="150"
            >
              <template v-slot="{ row }">
                <span
                  v-if="row.clean_condition"
                  :class="row.clean_condition === 'CLEAN' ? 'clean' : 'dirty'"
                >
                  {{ $t(`SPOTS.SPOT_CONDITION_${row.clean_condition}`) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.ORGANIZATION')"
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
              min-width="200"
            >
              <template v-slot="{ row }">
                <organization :organization="row.organization" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="180"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="far fa-ellipsis-v"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="actions-dropdown">
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_VIEW_SPOTS)"
                    >
                      <a
                        type="text"
                        @click="viewSpot(row)"
                        class="table-action view"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-eye.svg" alt="icon" />
                        </span>
                        <span class="text">{{
                          $t("COMMON.VIEW_DETAILS")
                        }}</span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_EDIT_SPOTS)"
                    >
                      <a
                        type="text"
                        @click.prevent="editSpot(row)"
                        class="table-action edit"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-edit.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.EDIT") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_DELETE_SPOTS)"
                    >
                      <a
                        type="text"
                        @click="deleteSpot(row.id)"
                        class="table-action delete"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-delete.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.DELETE") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        v-if="spots.length > 0"
        slot="footer"
        class="page-wrapper-table-footer"
      >
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
    <div
      v-if="(spots.length === 0) & !loading && isEmptyFilters()"
      class="no-data"
    >
      <div class="no-data-inner">
        <img src="/img/kw-empty-box.svg" alt="icon" />
        <p>{{ $t("SPOTS.NO_DATA") }}</p>
        <base-button
          class="kw-button add"
          icon
          size="sm"
          @click="onAddSpot"
          v-if="$currentUserCan($permissions.PERM_CREATE_SPOTS)"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{ $t("SPOTS.ADD_SPOT") }}</span>
        </base-button>
      </div>
    </div>

    <div
      v-if="spots.length === 0 && !loading && !isEmptyFilters()"
      class="no-data"
    >
      <div class="no-data-inner">
        <img src="/img/kw-empty-box.svg" alt="icon" />
        <p>{{ $t("COMMON.NO_SEARCH_RESULT") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import SpotTypeSelector from "@/components/SpotTypeSelector";
import SpotCategorySelector from "@/components/SpotCategorySelector";
import EquipmentSelector from "@/components/EquipmentSelector";
import LoadingPanel from "@/components/LoadingPanel.vue";

export default {
  name: "spot-list-table",

  components: {
    EquipmentSelector,
    SpotCategorySelector,
    SpotTypeSelector,
    BasePagination,
    // OrganizationSelector,
    LocationsSelector,
    LoadingPanel,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      spots: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      selectedSpotTypes: null,
      selectedSpotCategories: null,
      selectedEquipments: null,
      selectedTags: [],
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedSpotTypes: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedSpotCategories: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedEquipments: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,category,spotType,other_equipments",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        if (this.selectedSpotTypes) {
          params = {
            ...params,
            filter: { ...params.filter, in_types: this.selectedSpotTypes },
          };
        }

        if (this.selectedSpotCategories) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_categories: this.selectedSpotCategories,
            },
          };
        }

        if (this.selectedEquipments) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              has_equipments: this.selectedEquipments,
            },
          };
        }

        await this.$store.dispatch("spots/list", params);
        this.spots = this.$store.getters["spots/list"];
        this.total = this.$store.getters["spots/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editSpot(spot) {
      this.$emit("onEditSpot", spot);
    },
    viewSpot(spot) {
      this.$emit("onViewSpot", spot);
    },

    async deleteSpot(id) {
      const confirmation = await swal.fire({
        text: this.$t("SPOTS.DELETE_THIS_SPOT"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("spots/destroy", id);
          await this.getList();
          /* this.$notify({
            type: "success",
            message: this.$t("SPOTS.SPOT_DELETED"),
          }); */
          swal.fire({
            text: this.$t("SPOTS.DELETE_SPOT_SUCCESS"),
            type: "info",
            customClass: {
              popup: "delete-confirm",
            },
            buttonsStyling: false,
            showCancelButton: false,
            confirmButtonText: this.$t("COMMON.END"),
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    selectRow(row, ...args) {
      const target = args[1]?.target;

      if (target && target.classList.contains('fa-ellipsis-v')) {
        return false;
      }

      this.$emit("onViewSpot", row);
    },

    onAddSpot() {
      this.$emit("onAddSpot");
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
    isEmptyFilters() {
      return (
        !this.query &&
        !this.selectedOrganization &&
        !this.selectedLocations &&
        !this.selectedSpotTypes &&
        !this.selectedSpotCategories &&
        !this.selectedEquipments
      );
    },
  },
};
</script>
