<template>
  <div class="kw-tabs-wrapper-content">
    <div class="campsite-sheet">
      <h3>{{ $t("SPOTS.SPOT_CAPACITY") }}</h3>
      <div class="campsite-sheet-item">
        <div class="row">
          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_NAME") }}</dt>
            <dd>
              {{ spot.name }}
            </dd>
          </dl>

          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_CHILDREN_CAPACITY") }}</dt>
            <dd>
              {{ spot.children_capacity }}
            </dd>
          </dl>

          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_ADULT_CAPACITY") }}</dt>
            <dd>
              {{ spot.adults_capacity }}
            </dd>
          </dl>

          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_PET_CAPACITY") }}</dt>
            <dd>
              {{ spot.pets_capacity }}
            </dd>
          </dl>

          <!-- region superficie du terrain -->
          <dl class="col-sm-4" v-if="spot.spot_area && (isLandFormType || isSeasonalFormType)">
            <dt>{{ $t("SPOTS.SPOT_AREA") }} ({{ $t("COMMON.UNIT_SQUARE_FOOT") }})</dt>
            <dd>
              {{ spot.spot_area }}
            </dd>
          </dl>

          <dl class="col-sm-4" v-if="spot.services_included?.length && (isLandFormType || isSeasonalFormType)">
            <dt>{{ $t("SPOTS.SERVICES_INCLUDED") }}</dt>
            <dd>
              <service-included :value="spot.services_included" />
            </dd>
          </dl>

          <!-- region électicité -->
          <dl class="col-sm-4" v-if="spot.electricity?.length && (isLandFormType || isSeasonalFormType)">
            <dt>{{ $t("SPOTS.ELECTRICITY") }}</dt>
            <dd>
              <electricity :value="spot.electricity" />
            </dd>
          </dl>

          <dl class="col-sm-4" v-if="spot.kilo_watt &&  isSeasonalFormType">
            <dt>{{ $t("SPOTS.KILOWATT") }}</dt>
            <dd>
              {{ spot.kilo_watt }}
            </dd>
          </dl>

          <dl class="col-sm-4" v-if="spot.kilo_watt_measure_date && spot.kilo_watt &&  isSeasonalFormType">
            <dt>{{ $t("SPOTS.KILOWATT_DATE") }}</dt>
            <dd>
              {{ spot.kilo_watt_measure_date | moment("DD/MM/YYYY") }}
            </dd>
          </dl>
          <!-- endregion -->

          <!-- Equipements inclues -->
          <dl class="col-sm-4" v-if="spot.allowed_equipment?.length && (isLandFormType || isSeasonalFormType)">
            <dt>{{ $t("SPOTS.ALLOWED_EQUIPMENT") }}</dt>
            <dd>
              <allowed-equipments  :value="spot.allowed_equipment" />
            </dd>
          </dl>

          <!-- region extensions passagers -->
          <dl class="col-sm-4" v-if="spot.passenger_extensions&& (isLandFormType || isSeasonalFormType)">
            <dt>{{ $t("SPOTS.PASSENGER_EXTENSIONS") }}</dt>
            <dd>
              {{ spot.passenger_extensions }}
            </dd>
          </dl>
          <!-- endregion -->

          <!-- region extensions chauffeur -->
          <dl class="col-sm-4" v-if="spot.driver_extensions&& (isLandFormType || isSeasonalFormType)">
            <dt>{{ $t("SPOTS.DRIVER_EXTENSIONS") }}</dt>
            <dd>
              {{ spot.driver_extensions }}
            </dd>
          </dl>
          <!-- endregion -->

        </div>
      </div>
    </div>
    <div class="campsite-sheet">
      <h3>{{ $t("COMMON.LOCATION") }}</h3>
      <div class="campsite-sheet-item">
        <div class="row">
          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_LENGTH") }}</dt>
            <dd>
              {{ spot.equipment_length }}
            </dd>
          </dl>
          <dl class="col-sm-4">
            <dt>{{ $t("COMMON.CATEGORY") }}</dt>
            <dd>
                {{ spot.category?.name }}
            </dd>
          </dl>
          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_TYPE") }}</dt>
            <dd>
              {{ spot.spotType.name }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <!-- region heure d'arrivee et de depart -->
    <div class="campsite-sheet" v-if="!isSeasonalFormType">
      <h3>{{ $t("SPOTS.SPOT_SCHEDULE") }}</h3>
      <div class="campsite-sheet-item">
        <div class="row">
          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_ARRIVAL_TIME") }}</dt>
            <dd>
              {{ spot.arrival_time }}
            </dd>
          </dl>

          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_DEPARTURE_TIME") }}</dt>
            <dd>
              {{ spot.departure_time }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <!-- endregion -->
    <div class="campsite-sheet">
      <h3>{{ $t("SPOTS.SPOT_INCLUDED_EQUIPMENT") }}</h3>
      <div class="campsite-sheet-item">
        <div v-if="spot.spotType" class="equipments-included">
          <div class="equipments-included-group">
            <div
              v-for="eq in spot.other_equipments"
              :key="eq.id"
              class="item width_1-4"
            >
              <ul>
                <li v-if="eq.icon">
                  <el-image
                    style="width: 20px; height: 20px"
                    :src="eq.icon"
                    fit="scale-down"
                    alt="icon"
                  />
                </li>
                <li>{{ eq.name }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- region lits -->
    <div class="campsite-sheet" v-if="bedsIsset && (isPropertyFormType || isDefaultFormType)">
      <h3> {{ $t("SPOTS.BEDS") }}</h3>
      <div class="campsite-sheet-item">
        <div class="row">
          <dl class="col-sm-4" v-if="spot.beds_king_qty">
            <dt>
              {{ $t("SPOTS.BEDS_KING_QTY") }}
            </dt>
            <dd>
              {{ spot.beds_king_qty }}
            </dd>
          </dl>
          <dl class="col-sm-4" v-if="spot.beds_queen_qty">
            <dt>
              {{ $t("SPOTS.BEDS_QUEEN_QTY") }}
            </dt>
            <dd>
              {{ spot.beds_queen_qty }}
            </dd>
          </dl>
          <dl class="col-sm-4" v-if="spot.beds_double_qty">
            <dt>
              {{ $t("SPOTS.BEDS_DOUBLE_QTY") }}
            </dt>
            <dd>
              {{ spot.beds_double_qty }}
            </dd>
          </dl>
          <dl class="col-sm-4" v-if="spot.beds_twin_qty">
            <dt>
              {{ $t("SPOTS.BEDS_TWIN_QTY") }}
            </dt>
            <dd>
              {{ spot.beds_twin_qty }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <!-- endregion -->
    <!-- region salle de bain -->
    <div class="campsite-sheet" v-if="bathroomIsset && (isPropertyFormType || isDefaultFormType)">
      <div class="campsite-sheet-item">
        <h3>{{ $t("SPOTS.BATHROOMS") }}</h3>

        <div class="row">
          <dl class="col-sm-4" v-if="spot.bathroom_bathtub_qty">
            <dt>
              {{ $t("SPOTS.BATHROOM_BATHTUB_QTY") }}
            </dt>
            <dd>
              {{ spot.bathroom_bathtub_qty }}
            </dd>
          </dl>
          <dl class="col-sm-4" v-if="spot.bathroom_shower_qty">
            <dt>
              {{ $t("SPOTS.BATHROOM_SHOWER_QTY") }}
            </dt>
            <dd>
              {{ spot.bathroom_shower_qty }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <!-- endregion -->
  </div>
</template>

<script>
import { Image } from "element-ui";
import ServiceIncluded from "@/components/ServiceIncluded.vue";
import Electricity from "@/components/Electricity.vue";
import {FORM_TYPE_DEFAULT, FORM_TYPE_LAND, FORM_TYPE_PROPERTY, FORM_TYPE_SEASONAL} from "@/constants/common";
import AllowedEquipments from "@/components/AllowedEquipments.vue";

export default {
  name: "spot-view-sheet",

  props: ["spot"],

  components: {AllowedEquipments, [Image.name]: Image, Electricity, ServiceIncluded },

  data() {
    return {};
  },

  computed: {
    isDefaultFormType: function () {
      return this.spot.category.form_type === FORM_TYPE_DEFAULT;
    },
    isPropertyFormType: function () {
      return this.spot.category.form_type === FORM_TYPE_PROPERTY;
    },
    isLandFormType: function () {
      return this.spot.category.form_type === FORM_TYPE_LAND;
    },
    isSeasonalFormType() {
      return this.spot.category.form_type === FORM_TYPE_SEASONAL;
    },
    bedsIsset: function () {
      return (
          this.spot.beds_king_qty ||
          this.spot.beds_queen_qty ||
          this.spot.beds_double_qty ||
          this.spot.beds_twin_qty
      );
    },
    bathroomIsset: function() {
      return (this.spot.bathroom_bathtub_qty || this.spot.bathroom_shower_qty) ;
    }
  },

  created() {},

  methods: {
    spotUpdated() {
      this.$emit("spotUpdated", true);
    },
  },

  mounted() {},

  watch: {
    spot(spot) {},
  },
};
</script>
